









import Vue from "vue";
import OrderDetails from "@/components/inventory/OrderDetails.vue";
import ActionButtons from "@/components/ActionButtons.vue";

export default Vue.extend<any, any, any, any>({
  name: "OrderPage",
  components: { OrderDetails, ActionButtons },
});
