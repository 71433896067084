




























































































































































































































































































































































































































































































































import Vue from "vue";
import OrderStoreModule from "@/store/modules/order";
import { createNamespacedHelpers } from "vuex";
import { Business, Role } from "@/types";
import OrderItem from "@/components/inventory/OrderItem.vue";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { PHONE_REGEX_KE } from "@/util/constants";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;
const stripePubKey = process.env.VUE_APP_STRIPE_PUB_KEY;

const { mapGetters: OrderGetters, mapActions: OrderActions } =
  createNamespacedHelpers("ORDER_");

export default Vue.extend<any, any, any, any>({
  name: "OrderDetails",
  components: { OrderItem, StripeElementCard },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
    fulfilDialog: false,
    fulfilForm: {
      courier: "",
      trackingId: "",
      notes: "",
    },
    refunding: false,
    isLoading: false,
    processingPayment: false,
    orderPaymentDialog: false,
    payment: {
      mode: "m-pesa",
      amount: 0,
      phone: "",
      transactionId: "",
    },
    stripePk: stripePubKey,
    mpesaPhoneRules: [
      (v: string) => !!v || "Phone Number is required",
      (v: string) => PHONE_REGEX_KE.test(v) || "Invalid phone number",
    ],
  }),
  computed: {
    ...OrderGetters(["getOrder", "orderPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business;
    },
    orderId(): string {
      return this.$route.params.orderId;
    },
    order(): any {
      return this.getOrder(this.orderId);
    },
    total_paid(): any {
      if (this.order && this.order.payments) {
        const sum = Object.values<{ amount: number }>(
          this.order.payments
        ).reduce((p, c) => p + c.amount, 0);
        return sum;
      }
      return 0;
    },
    hideCheckout(): boolean {
      if (
        this.order.status != "cancelled" &&
        this.order.paymentStatus != "completed"
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    role() {
      this.loadOrder();
    },
    order() {
      if (this.order) {
        this.payment.amount = this.order.cost;
      }
    },
  },
  created() {
    this.loadOrder();
  },
  methods: {
    ...OrderActions([
      "fetchOrderList",
      "updateStatus",
      "fulfilOrder",
      "refundOrder",
      "retryOrderPayment",
      "checkOrderPayment",
    ]),
    tokenCreated(tkn: any) {
      if (!tkn) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Stripe Token not Provided!",
        });
        this.processingPayment = false;
        return;
      }

      const payload = {
        method: "card",
        source: tkn.id,
        currency: "KES",
        amount: this.order.cost,
      };
      this.processingPayment = true;
      this.retryOrderPayment({ id: this.orderId, payment: payload })
        .then(() => {
          this.$swal.fire({
            icon: "success",
            title: "Payment Processing",
            text: "Card payment is processing",
          });
          this.loadOrder();
          this.resetFulfilForm();
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Order Payment Failed!",
            text: "Error while processing Order Payment",
          });
        })
        .finally(() => {
          this.processingPayment = false;
        });
    },
    submitPayment() {
      const { mode = "m-pesa", phone, transactionId } = this.payment;

      if (!this.vendor) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Vendor Details not found!",
        });
        return;
      }

      if (!this.order) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Order Details not found!",
        });
        return;
      }

      if (mode == "m-pesa") {
        const payload = {
          method: "m-pesa",
          amount: this.order.cost,
          currency: "KES",
          source: phone,
        };

        if (transactionId) {
          this.processingPayment = true;
          this.checkOrderPayment({
            id: this.order._id,
            data: { transactionId },
          })
            .then(() => {
              this.resetFulfilForm();
              this.loadOrder();
              this.$swal.fire(
                "Success",
                "Order payment Checking underway!",
                "success"
              );
            })
            .catch((e) => {
              this.$swal.fire({
                icon: "error",
                title: "Order Payment Failed!",
                text: "Error while processing Order Payment",
              });
            })
            .finally(() => {
              this.processingPayment = false;
            });
          return;
        }
        this.processingPayment = true;
        this.retryOrderPayment({ id: this.orderId, payment: payload })
          .then(() => {
            this.$swal.fire({
              icon: "success",
              title: "Payment Processing",
              text: "Mpesa payment is processing",
            });
            this.loadOrder();
            this.resetFulfilForm();
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: "Order Payment Failed!",
              text:
                (e as Error).message || "Error while processing Order Payment",
            });
          })
          .finally(() => {
            this.processingPayment = false;
          });
      }

      if (mode == "card") {
        (this.$refs.stripyCard as any).submit();
      }
    },
    resetFulfilForm() {
      this.orderPaymentDialog = false;
      this.payment = {
        phone: "",
        mode: "m-pesa",
        amount: this.order?.cost,
        transactionId: "",
      };

      this.fulfilForm = {
        courier: "",
        trackingId: "",
        notes: "",
      };
    },
    submitFulfil() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.fulfilOrder({ id: this.orderId, data: this.fulfilForm })
        .then((order) => {
          if (order) {
            this.resetFulfilForm();
            this.fulfilDialog = false;
            this.loadOrder();
          }
        })
        .finally(() => (this.isLoading = false));
    },
    refund() {
      if (this.refunding) {
        return;
      }
      this.refunding = true;
      this.refundOrder({ id: this.orderId })
        .then(() => {
          this.loadOrder();
        })
        .finally(() => (this.refunding = false));
    },
    markAsDelivered() {
      if (this.order) {
        this.updateStatus({ id: this.orderId, status: "delivered" }).then(
          () => {
            this.loadOrder();
          }
        );
      }
    },
    loadOrder() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&orderId=${this.orderId}`;
        this.fetchOrderList(params);
      }
    },
    shipOrder() {
      if (this.order) {
        this.updateStatus({ id: this.orderId, status: "shipping" }).then(() => {
          this.loadOrder();
        });
      }
    },
    cancelOrder() {
      if (this.order) {
        this.updateStatus({ id: this.orderId, status: "cancelled" }).then(
          () => {
            this.loadOrder();
          }
        );
      }
    },
    retryPayment() {
      if (this.order) {
        this.processingPayment = true;
        this.retryOrderPayment({ id: this.orderId })
          .then(() => {
            this.loadOrder();
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: "Order Payment Failed!",
              text:
                (e as Error).message || "Error while processing Order Payment",
            });
          })
          .finally(() => {
            this.processingPayment = false;
          });
      }
    },
    deliverOrder() {
      if (this.order) {
        this.updateStatus({ id: this.orderId, status: "delivered" }).then(
          () => {
            this.loadOrder();
          }
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_")) {
      this.$store.registerModule("ORDER_", OrderStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ORDER_");
  },
});
