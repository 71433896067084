














































































































































































import Vue from "vue";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "OrderItem",
  props: {
    item: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
  }),
});
